<script>
import moment from 'moment'
import STable from '@/components/Table'
import { checkDomainDNS, deleteCertInfo, loadCertInfoList } from '@/api'
import EditUnitName from '@/views/cert/modal/EditUnitName.vue'

const checkTimers = []
export default {
  name: 'Domain',
  components: {
    STable
  },

  data () {
    return {
      columns: [
        {
          title: this.$t('cert.domain'),
          dataIndex: 'host_name',
          key: 'host_name',
          width: 200
        },
        {
          title: this.$t('cert.dns.config'),
          dataIndex: 'dns',
          key: 'dns',
          scopedSlots: { customRender: 'dns' },
          align: 'center',
          width: 140
        },
        {
          title: this.$t('cert.status'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center',
          width: 130
        },
        {
          title: this.$t('cert.validity.period'),
          dataIndex: 'cert',
          scopedSlots: { customRender: 'cert' },
          align: 'center',
          width: 200
        },
        {
          title: this.$t('cert.provider'),
          dataIndex: 'cert_provider',
          scopedSlots: { customRender: 'cert_provider' },
          align: 'center',
          width: 120
        },
        // {
        //   title: this.$t('cert.unit.name'),
        //   dataIndex: 'unit_name',
        //   align: 'center',
        //   width: 180
        // },
        {
          title: this.$t('cert.add.time'),
          dataIndex: 'create_time',
          scopedSlots: { customRender: 'create_time' },
          align: 'center',
          width: 160
        },
        {
          title: this.$t('cert.remark'),
          dataIndex: 'remark',
          align: 'center'
        },

        {
          title: this.$t('user.action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
          width: 180
        }
      ],
      checkIndex: 0,
      queryParams: {},
      loadData: (parameter) => {
        const _this = this
        if (checkTimers && checkTimers.length > 0) {
          checkTimers.forEach(function (item) {
            clearTimeout(item)
          })
        }
        return loadCertInfoList(Object.assign(this.queryParams, parameter)).then(res => {
          const data = res.result.data
          this.checkIndex = 0
          for (let i = 0; i < data.length; i++) {
            (function (index, item) {
              const timer = setTimeout(function () {
                checkDomainDNS({ server_name: '', server_host_name: item.host_name, server_port: '443' }).then(res => {
                  if (res.code === 0) {
                    item.dns = res.data.match ? 1 : 2
                    _this.$refs.table.updateSourceData(index, item)
                  }
                })
              }, i * 400 + 300)
              checkTimers.push(timer)
            })(i, data[i])
          }
          return res.result
        })
      }
    }
  },
  beforeDestroy () {
    if (checkTimers && checkTimers.length > 0) {
      checkTimers.forEach(function (item) {
        clearTimeout(item)
      })
    }
  },
  methods: {
    moment,
    unit () {
      this.showModal(EditUnitName, this.$t('cert.unit.name.edit'), 500, 500)
    },
    handleConfirmDel (record) {
      deleteCertInfo({ _id: record._id }).then(res => {
        this.$refs.table.refresh()
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="ant-card">
      <s-table
        ref="table"
        :columns="columns"
        :data="loadData"
        bordered
        :scroll="{x:1400}"
        size="small">
        <div slot="status" slot-scope="text,record">
          <a-tag v-if="record.cert_status==1" color="green">{{ $t('user.website.normal') }}</a-tag>
          <a-tag v-if="record.cert_status==0">{{ $t('user.website.unkonown') }}</a-tag>
          <a-tag v-if="record.cert_status==2" color="orange">{{ $t('user.website.notgen') }}</a-tag>
          <a-tag v-if="record.cert_status==3" color="red">{{ $t('user.website.certExpiration') }}</a-tag>
        </div>
        <div slot="create_time" slot-scope="text,record">{{ moment(record.create_time).format('YYYY-MM-DD HH:mm:ss')
        }}
        </div>
        <div slot="dns" slot-scope="text,record">

          <a-icon v-if="record.dns===1" type="check-circle" style="font-size: 1.4rem;color:green"></a-icon>
          <a-icon v-if="record.dns===2" type="close-circle" style="font-size: 1.4rem;color:red;"></a-icon>
          <a-spin v-if="!record.dns"></a-spin>
        </div>
        <div slot="cert_provider" slot-scope="text,record">
          <span v-if="record.cert_provider===1">Let's Encrypt</span>
          <span v-if="record.cert_provider===2">ZeroSSL</span>
        </div>
        <div slot="cert" slot-scope="text,record">
          <div v-if="record.show_progress" style="padding: 0 12px;">
            <a-progress
              :percent="record.cert_use_days*100/record.cert_total_days"
              :status="(record.cert_total_days-record.cert_use_days>15?'success':'exception')">
              <template #format="percent">
                <span>{{ record.cert_use_days }}/{{ record.cert_total_days }}</span>
              </template>
            </a-progress>
          </div>
          <div v-else>-</div>
        </div>
        <div slot="action" slot-scope="text,record">
          <a-popconfirm :title="$t('cert.action.delete.tip')" :ok-text="$t('user.action.ok')" :cancel-text="$t('user.action.cancel')" @confirm="handleConfirmDel(record)">
            <a style="color: red;">{{ $t('user.action.delete') }}</a>
          </a-popconfirm>

          <a-divider type="vertical"></a-divider>
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              {{ $t('user.action.more') }}
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;">{{ $t('user.cert.download') }}</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">{{ $t('user.cert.reissueCert') }}</a>
              </a-menu-item>
              <!--              <a-menu-item>-->
              <!--                <a @click="unit">{{ $t('cert.unit.name.edit') }}</a>-->
              <!--              </a-menu-item>-->

            </a-menu>
          </a-dropdown>
        </div>
      </s-table>
    </div>
  </div>
</template>

<style scoped>

</style>

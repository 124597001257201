import { axios } from '@/utils/request'

export function loadWebsiteList (parameter) {
  return axios({
    url: '/user/website/list',
    method: 'post',
    data: parameter
  })
}

export function loadCertInfoList (parameter) {
  return axios({
    url: '/user/cert/list',
    method: 'post',
    data: parameter
  })
}

export function deleteCertInfo (parameter) {
  return axios({
    url: '/user/cert/del',
    method: 'post',
    data: parameter
  })
}

export function loadPointRecordList (parameter) {
  return axios({
    url: '/user/point/record/list',
    method: 'post',
    data: parameter
  })
}

export function loadCloudDomainInfoList (parameter) {
  return axios({
    url: '/user/cloud/domain/list',
    method: 'post',
    data: parameter
  })
}

export function checkDomainDNS (parameter) {
  return axios({
    url: '/user/cloud/domain/dns/check',
    method: 'post',
    data: parameter
  })
}

export function saveCloudAPIAuthInfo (parameter) {
  return axios({
    url: '/user/cloud/auth/save',
    method: 'post',
    data: parameter
  })
}

export function loadCloudSettingInfoList (parameter) {
  return axios({
    url: '/user/cloud/setting/list',
    method: 'post',
    data: parameter
  })
}

export function loadMessageList (parameter) {
  return axios({
    url: '/user/massage/list',
    method: 'post',
    data: parameter
  })
}

<script>
import { getI18nLanguage } from '@/locales'

export default {
  name: 'EditUnitName',
  data () {
    return {
      imgUrl: getI18nLanguage().startsWith('zh') ? '/images/unit_name_zh.png' : '/images/unit_name_en.png'
    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px 16px;">
    <div>
      <p>{{ $t('cert.unit.name.tip') }}</p>
    </div>
    <div>
      <img :src="imgUrl" style="width: 100%;"/>
    </div>
    <div>
      <p>{{ $t('cert.unit.name.tip2') }}</p>
    </div>
    <div style="display: flex;">
      <span style="line-height: 32px;"> {{ $t('cert.unit.name') }}:</span>
      <a-input style="flex:1;margin-left: 6px;"></a-input>
    </div>
    <div style="margin-top: 20px;text-align: center;">
      <a-button type="primary">{{ $t('user.action.save') }}</a-button>
    </div>
  </div>
</template>

<style scoped>

</style>
